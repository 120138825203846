<template>
  <div class="indexs">
    <div class="today">
      
    </div>
    <div id="history"></div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, getCurrentInstance } from 'vue'
import tables from '@/components/table/index.vue'
export default defineComponent({
  name: '',
  components: {
    tables
  },
  setup() {
    const { ctx }=getCurrentInstance()
    let todayIndexs={}
    let historyIndexs=[]
    // 获取今日指数
    async function getTodayIndexs(){
      let res=await ctx.$api.getTodayIndexs()
      if (res) {
        todayIndexs=res
      }
    }
    // 获取指数历史行情
    async function getHistoryIndexs(){
      let res=await ctx.$api.getHistoryIndexs()
      if (res) {
        historyIndexs=res
      }
    }

    // 生成echart图
    function makeEchart(){
      
    }

    onMounted(() => {
      getTodayIndexs()
      getHistoryIndexs()
    })
    return {
      todayIndexs,
      historyIndexs
    }
  },
})

</script>
<style scoped lang='scss'>
</style>